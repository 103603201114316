<template>
    <div class="scrollbar__wrap">
        <el-container>
			<el-header>
                <div class="header">
                    <div class="header-logo">
                        <!-- <img src="../../assets/images/logo.png" alt=""> -->
                        <img src="../../assets/images/logo.png" alt="" v-if="logo.logo_url==''">
                        <img :src="logo.logo_url" alt="" v-if="logo.logo_url!=''">
                    </div>
                    
                    <div class="header-nav">
                         <!-- menu-trigger="click" -->
                        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                            <el-menu-item index="1_1">首页</el-menu-item>
                            <el-menu-item index="2_2">平台介绍</el-menu-item>
                            <el-menu-item index="7_7">本地商务服务</el-menu-item>
                            <el-submenu index="3" default-openeds>
                                <template slot="title">外贸营销平台工具</template>
                                <el-menu-item
                                :index="value.id.toString()"
                                v-for="(value, index) in yingxiaoList"
                                :key="index"
                                >{{ value.name }}</el-menu-item >
                                <!-- <el-menu-item index="3-1">跨境电商平台</el-menu-item>
                                <el-menu-item index="3-2">外贸营销工具</el-menu-item>
                                <el-menu-item index="3-3">海外仓营销</el-menu-item>
                                <el-menu-item index="3-4">进口商城</el-menu-item>
                                <el-menu-item index="3-5">国际招标与采购信息</el-menu-item>
                                <el-menu-item index="3-6">国际会展</el-menu-item>
                                <el-menu-item index="3-7">供求对接</el-menu-item>
                                <el-menu-item index="3-8">国外客户信息库</el-menu-item> -->
                            </el-submenu>
                            <el-submenu index="4">
                                <template slot="title">外贸综合服务</template>
                                <el-menu-item
                                :index="value.id.toString()"
                                v-for="(value, index) in fuwuList"
                                :key="index"
                                >{{ value.name }}</el-menu-item >
                                <!-- <el-menu-item index="4-1">进出口代理</el-menu-item>
                                <el-menu-item index="4-2">投资与贸易咨询</el-menu-item>
                                <el-menu-item index="4-3">商务考察</el-menu-item>
                                <el-menu-item index="4-4">金融保险</el-menu-item>
                                <el-menu-item index="4-5">国际物流</el-menu-item>
                                <el-menu-item index="4-6">法律财税</el-menu-item>
                                <el-menu-item index="4-7">会议论坛</el-menu-item>
                                <el-menu-item index="4-8">涉外咨讯</el-menu-item>
                                <el-menu-item index="4-9">文化交流</el-menu-item>
                                <el-menu-item index="4-10">其他</el-menu-item>
                                <el-menu-item index="4-11">其他</el-menu-item>
                                <el-menu-item index="4-12">其他</el-menu-item> -->
                            </el-submenu>
                            <el-menu-item index="5_5">产业品牌出海</el-menu-item>
                            <el-menu-item index="6_6">海外仓服务</el-menu-item>
                            
                            <el-menu-item index="8_8">合作伙伴</el-menu-item>
                            <el-menu-item index="9_9">联系我们</el-menu-item>
                        </el-menu>
                    </div>  

                    <div class="header-search">
                        <div class="header-search-img" @click="goTo('search_list')">
                            <img src="../../assets/images/search.png" alt="">
                            <div>搜索</div>
                        </div>
                        <div class="header-search-img" v-if="!isLogin" @click="goTo('login')">
                            <img src="../../assets/images/my.png" alt="">
                            <div>登录</div>
                        </div>
                        <div class="header-search-img"  v-if="isLogin" @click="goOut()">
							<img src="../../assets/images/loginout.png" />
                            <div>退出</div>
						</div>
                    </div>
                </div>
            </el-header>

            <!-- 主要内容 -->
			<div class="m-main">
				<template  v-if="$route.meta.isKeep">
                    <keep-alive >
                        <router-view />
                    </keep-alive>
				</template>
				<template v-else>
					<router-view />
				</template>
			</div>
                
            <el-footer>
                <div class="footer-wrap">
                    <div class="footer">
                        <div class="footer-logo">
                            <img src="../../assets/images/footer/logo.png" alt="" v-if="logo.bottom_logo_url==''">
                            <img :src="logo.bottom_logo_url" alt="" v-if="logo.bottom_logo_url!=''">
                        </div>
                        <div class="footer-list">
                            <div class="footer-list_wrap footer-kuaisu">
                                <div class="list_text">快速导航</div>
                                <ul>
                                    <li @click="goTo('/')">首页</li>
                                    <li @click="goTo('platform')">平台介绍</li>
                                    <li @click="goTo('bdswfw')">本地商务服务</li>
                                    <li @click="goTo('kjdspt')">外贸营销平台工具</li>
                                    <li @click="goTo('wmzhfw',11)">外贸综合服务</li>
                                    <li @click="goTo('cyppch')">产业品牌出海</li>
                                    <li @click="goTo('hwzy')">海外资源</li>                                    
                                    <li @click="goTo('partner')">合作伙伴</li>
                                    <li @click="goTo('contact_us')">联系我们</li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-list">
                            <div class="footer-list_wrap">
                                <div class="list_text">联系方式</div>
                                <ul>
                                    <li>{{lianxiList.title}}</li>
                                    <li v-for="(item,index) in lianxiList.lxs" :key="index">
                                        <p>联系人：{{item.full}}</p>
                                        <p>电话：{{item.phone}}</p>
                                        <p v-if="item.email">邮箱：{{item.email}}</p>
                                    </li>
                                    <li class="wangzhi">
                                        <p>网址：</p>
                                        <div class="wangzhi-link">
                                            <div class="wangzhi-link-a" v-for="(item,index) in lianxiList.link" :key="index" @click="toLink(item.url)">{{item.url}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-code">
                            <img :src="lianxiList.follow_url" alt="">
                            <div>扫码关注</div>
                        </div>
                        <div class="footer-list">
                            <div class="footer-list_wrap">
                                <ul class="footer-fenxiang">
                                    <li>快速分享：</li>
                                    <div class="footer-fenxiang_img">
                                        <div class="footer-fengxiang_img">
                                            <img src="../../assets/images/footer/1.png" alt="">
                                            <div class="fx">
                                                <img :src="lianxiList.f_qq_url" alt="">
                                                <img src="../../assets/images/tt.png" alt="" class="sanjiao">
                                            </div>
                                        </div>
                                        <div class="footer-fengxiang_img">
                                            <img src="../../assets/images/footer/2.png" alt="">
                                            <div class="fx">
                                                <img :src="lianxiList.f_wx_url" alt="">
                                                <img src="../../assets/images/tt.png" alt="" class="sanjiao">
                                            </div>
                                        </div>
                                        <div class="footer-fengxiang_img">
                                            <img src="../../assets/images/footer/3.png" alt="">
                                            <div class="fx">
                                                <img :src="lianxiList.f_wb_url" alt="">
                                                <img src="../../assets/images/tt.png" alt="" class="sanjiao">
                                            </div>
                                        </div>                            
                                    </div>
                                    <div class="footer-fenxiang_img">
                                        <div class="footer-fengxiang_img">
                                            <img src="../../assets/images/footer/4.png" alt="">
                                            <div class="fx">
                                                <img :src="lianxiList.f_tt_url" alt="">
                                                <img src="../../assets/images/tt.png" alt="" class="sanjiao">
                                            </div>
                                        </div>
                                        <div class="footer-fengxiang_img">
                                            <img src="../../assets/images/footer/5.png" alt="">
                                            <div class="fx">
                                                <img :src="lianxiList.f_fb_url" alt="">
                                                <img src="../../assets/images/tt.png" alt="" class="sanjiao">
                                            </div>
                                        </div>
                                        <div class="footer-fengxiang_img">
                                            <img src="../../assets/images/footer/6.png" alt="">
                                            <div class="fx">
                                                <img :src="lianxiList.f_ins_url" alt="">
                                                <img src="../../assets/images/tt.png" alt="" class="sanjiao">
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>                
                        </div>
                    </div>
                    <div class="footer-con" @click="toLink('https://beian.miit.gov.cn/')">{{lianxiList.record}}</div>
                </div>

            </el-footer>
        </el-container>
        <el-backtop :visibility-height="100" :bottom="100">
            <img src="../../assets/images/gotop.png" alt="" class="gotop">
        </el-backtop>

        <!-- 在线客服 -->
        <div class="kefu">
            <div class="kefu_p">在线客服</div>                    
            <a :href="qqurl" target="_blank">
                <img src="../../assets/images/qq.png" border="0" alt="QQ" />
            </a>
        </div>

    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex'    
    import CONFIG from "../../utils/config.js"
    import {getplates , getwes,getzhans} from '../../api/index'
    export default {
        name:'Mian',
        data() {
            return {
                activeIndex: '1_1',
                key: 0,
                baseUrl:CONFIG.baseUrl,
                yingxiaoList:[],
                fuwuList:[],
                lianxiList:[],
                logo:[],
                qqurl:`http://wpa.qq.com/msgrd?v=3&uin=746310217&site=qq&menu=yes`
            };
        },
        computed: {
            ...mapState(['routeIndex','routeId','isLogin','zh_id'])         
        },
        created() {
            this.getplates();
            this.getplates2();
            this.getwes();
            this.setIndex()   
            this.getzhans()
            // console.log(this.routeId)   
        },
        watch: {
            activeIndex(val) {
                // console.log(val);
            },
            routeIndex(toVal) {
                // console.log(toVal)
                this.setIndex();
            },
            routeId(){
                this.setIndex();
            }
        },
        methods: {
            toLink(link){
                window.open(link)
            },
            getzhans(){
                getzhans({
                    id:this.zh_id
                }).then((res)=>{
                    // console.log(res);
                    this.logo=res.data
                })
            },
            ...mapActions([
				'vuex_out'
			]),
            goOut() {
				this.$confirm('确认退出登录吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.vuex_out()					
				})
			},

            // 跳转页面
            goTo(url,id) {
                this.$router.push({ 
                    path: url ,
                    query:{
                       hzy_id:id
                    }
                });
            },
            setIndex() {
                let routeMap = {
                    index: "1_1",
                    platform: "2_2",
                    kjdspt: "3",
                    wmyxgj: "4",
                    hwcyx:"6",
                    jksc:"7",
                    zbcg:"5",
                    gjhz:"8",
                    gqdj:"9",
                    gwkhxxk:"10",
                    cyppch:'5_5',
                    hwzy:'6_6',
                    bdswfw:'7_7',
                    partner:"8_8",
                    contact_us: "9_9", 
                };
                // console.log(this.$route.query.hzy_id)
                if (this.$route.query.hzy_id) {
                    var index = this.$route.query.hzy_id;
                    // if (this.$route.query.hzy_id==11) {
                    //     var index = '11';
                    // }else if (this.$route.query.hzy_id==12) {
                    //     var index = '12';
                    // }else if (this.$route.query.hzy_id==13) {
                    //     var index = '13';
                    // }else if (this.$route.query.hzy_id==14) {
                    //     var index = '14';
                    // }else if (this.$route.query.hzy_id==15) {
                    //     var index = '15';
                    // }else if (this.$route.query.hzy_id==16) {
                    //     var index = '16';
                    // }else if (this.$route.query.hzy_id==17) {
                    //     var index = '17';
                    // }else if (this.$route.query.hzy_id==18) {
                    //     var index = '18';
                    // }else if (this.$route.query.hzy_id==19) {
                    //     var index = '19';
                    // }
                }else if (this.$route.query.wid) {
                    if (this.$route.query.wid==4) {
                        var index = '4';
                    }else if (this.$route.query.wid==129) {
                        var index = '129';
                    }else if (this.$route.query.wid==130) {
                        var index = '130';
                    }else if (this.$route.query.wid==131) {
                        var index = '131';
                    }else if (this.$route.query.wid==132) {
                        var index = '132';
                    }
                } else{
                    var index = routeMap[this.$route.name];
                }
        
                this.activeIndex = index;           
                this.key = index;
                // console.log(index)
            },
             //获取外贸营销平台工具分类
            getplates(){
                getplates({
                    p_id:1
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.yingxiaoList=res.data                        
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //获取外贸综合服务分类
            getplates2(){
                getplates({
                    p_id:2
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.fuwuList=res.data                        
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //获取底部联系我们
            getwes(){
                getwes().then((res)=>{
                    console.log('客服qqq',res.data.txts.qq_kefu)
                    if (res.code==200) {
                        this.lianxiList=res.data;
                        if(res.data.txts.qq_kefu){
                            this.qqurl = `http://wpa.qq.com/msgrd?v=3&uin=${res.data.txts.qq_kefu}&site=qq&menu=yes`
                        }
                        
                    }
                })
            },

            
            handleSelect(key, keyPath) {
                console.log(key,keyPath[0]);
                // 避免重复点击
                // if(this.key==key){return false}
                if (key == '1_1') {
                    this.$router.push("/");
                    this.key = key
                }else if (key == '2_2') {
                    this.$router.push("/platform");
                    this.key = key
                }
                else if (key == "3") {
                    // this.$router.push("kjdspt");
                    this.$router.push({path:"/kjdspt",query:{hzy_id:key}})
                    this.key = key
                }
                
                else if (key == "4") {
                    this.$router.push({path:"/wmyxgj",query:{wid:4}});                    
                    this.key = key
                }else if (key == "129") {
                    this.$router.push({path:"/wmyxgj",query:{wid:129}});                    
                    this.key = key
                }else if (key == "130") {
                    this.$router.push({path:"/wmyxgj",query:{wid:130}});                    
                    this.key = key
                }else if (key == "131") {
                    this.$router.push({path:"/wmyxgj",query:{wid:131}});                    
                    this.key = key
                }else if (key == "132") {
                    this.$router.push({path:"/wmyxgj",query:{wid:132}});                    
                    this.key = key
                }         
                
                else if (key == "6") {
                    // this.$router.push("hwcyx");
                    this.$router.push({path:"/hwcyx",query:{hzy_id:key}})
                    this.key = key
                }else if (key == "7") {
                    // this.$router.push("jksc");
                    this.$router.push({path:"/jksc",query:{hzy_id:key}})
                    this.key = key
                }else if (key == "5") {
                    // this.$router.push("zbcg");
                    this.$router.push({path:"/zbcg",query:{hzy_id:key}})
                    this.key = key
                }else if (key == "8") {
                    // this.$router.push("gjhz");
                    this.$router.push({path:"/gjhz",query:{hzy_id:key}})
                    this.key = key
                }else if (key == "9") {
                    // this.$router.push("gqdj");
                    this.$router.push({path:"/gqdj",query:{hzy_id:key}})
                    this.key = key
                }else if (key == "10") {
                    // this.$router.push("gwkhxxk");
                    this.$router.push({path:"/gwkhxxk",query:{hzy_id:key}})
                    this.key = key
                }
                if (keyPath[0]=='4') {
                    this.$router.push({path:"/wmzhfw",query:{hzy_id:key}});
                }
                // else if (key == "11") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:11}});
                //     this.key = key
                // }else if (key == "12") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:12}});
                //     this.key = key
                // }else if (key == "13") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:13}});
                //     this.key = key
                // }else if (key == "14") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:14}});
                //     this.key = key
                // }else if (key == "15") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:15}});
                //     this.key = key
                // }else if (key == "16") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:16}});
                //     this.key = key
                // }else if (key == "17") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:17}});
                //     this.key = key
                // }else if (key == "18") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:18}});
                //     this.key = key
                // }else if (key == "19") {
                //     this.$router.push({path:"wmzhfw",query:{hzy_id:19}});
                //     this.key = key
                // }

                else if (key == "5_5") {
                    this.$router.push("/cyppch");
                    this.key = key
                }else if (key == "6_6") {
                    this.$router.push("/hwzy");
                    this.key = key
                }else if (key == "7_7") {
                    this.$router.push("/bdswfw");
                    this.key = key
                }else if (key == '8_8') {
                    this.$router.push("/partner");
                    this.key = key
                }else if (key == '9_9') {
                    this.$router.push("/contact_us");
                    this.key = key
                }
                  
            },
        }
    }
</script>


<style lang="less">
.kefu{
    position: fixed;
    right: -102px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999999;
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 32px 3px rgba(6,7,8,8%);
    cursor: pointer;
    // border-radius: 10px 0 0 10px;
    overflow: hidden;
    transition: all .5s;
    .kefu_p{
        width: 45px;
        color: #4CAFE9;
        background: #f1f1f1;
        height: 140px;
        writing-mode: vertical-rl;
        text-align: center;
        line-height: 45px;
        letter-spacing:5px;
        border: 1px solid #ddd;
        border-right: none;
    }
    a{padding:0 25px;height: 140px;border: 1px solid #ddd;display: flex;align-items: center;}
    img{
        width: 50px;
    }
    &:hover{
        right: 0;
        transition: all .5s;
    }
}


	.el-header {
        background: #fff;
        height: 84px !important;
    }
	.el-footer {
        width: 100%;
		// height: 351px !important;
        height: auto !important;
		background: #333333;
		padding: 0 !important;
	}
    .el-menu--horizontal>.el-menu--popup-bottom-start{
        max-width: 500px!important;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px 10px;
    }
    .el-menu--horizontal>.el-menu--popup-bottom-start>.el-menu-item{
        font-size: 16px;
        width: 33.3%;
        text-align: center;
        padding: 5px 0 !important;
        height: auto !important;
    }

    // .el-menu--horizontal>.el-menu--popup-bottom-start>.el-menu-item:hover{
    //     background: #409EFF;
    //     color: #fff !important;
    //     transition: all .1s !important;
    // }
</style>

<style lang="less" scoped>
.m-main {
    min-height: calc(100vh - 425px);
    background: #fff;
}

/deep/ .el-menu.el-menu--horizontal{border-bottom: none;}
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__title, 
/deep/ .el-menu--horizontal>.el-menu-item{
    font-size: 16px;
    color: #262626;
    height: 66px;
    line-height: 66px;
    border: none;
}
/deep/ .el-menu-item, 
/deep/ .el-submenu__title{
    padding: 0  15px;
}
/deep/ .el-menu--horizontal>.el-menu-item.is-active {color: #409EFF;font-size: 16px;}
/deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{color: #409EFF;font-size: 16px;}
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{margin-left: 2px;font-size: 18px;color: #707071;}


.header{
    width: 1600px;
    align-items: center;
    display: flex;justify-content: space-between;margin:0 auto;padding: 4px 0;color: #262626;
    .header-logo{
        // width: 66px;
        height: 80px;
        // height: auto;
        display: flex;
        align-items: center;
        img{
            width: auto;
            height: 100%;
            display: block;
            vertical-align: middle;
        }
    }
    .header-search{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header-search-img{
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            img{width: 19px;}
            div{font-size: 15px;margin: 0 16px 0 6px;}
        }
    }
    
}

@media (max-width: 1350px){
    .header{
        width: 1500px;
    }
}



.footer{
    // height: 285px;
    padding-top: 23px;width: 1600px;margin: 0 auto;display: flex;justify-content: space-between;
    .footer-logo{width: 120px;
        img{width: 100%;}
    }
    .footer-list{
        padding-top: 13px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        ul{
            padding-top: 30px;
            li{
                color: #797979;
                font-size: 16px;
                // cursor: pointer;
                padding-bottom: 20px;
                display: flex;
                p{margin-left: 20px;}
                p:first-of-type{margin-left: 0px;}
            }
            .wangzhi-link{
                color: #797979;
                font-size: 16px;     
                // display: flex;
                // flex-wrap: wrap;           
                .wangzhi-link-a{
                    // width: 50%;
                    cursor: pointer;
                    margin-bottom: 5px;
                    &:hover{color: #fff;}
                }
               
            }
        }
        .footer-kuaisu{
            width: 300px;
            ul{
                padding-top: 30px;
                height: 220px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                li:hover{
                    color: #A7A7A7;
                    font-size: 16px;
                    text-decoration: underline;
                    cursor: pointer;
                }
                li{margin-right: 54px;}
            }
        }
        .list_text{font-size: 18px;color: #BBBBBB;font-weight: bold;height: 25px;}
        .footer-fenxiang{
            margin-top: 25px;margin-bottom: 12px;
            .footer-fenxiang_img{
                margin-top: 17px;display: flex;align-items: center;
                .footer-fengxiang_img{
                    margin-right: 25px;cursor: pointer;position: relative;
                    img{width: 25px;}
                    .fx{width:80px;height: 80px;position: absolute;top: -107px;left: 50%;transform: translateX(-50%);background: #fff;padding: 5px;display:none;
                        img{width: 100%;height: 100%;}
                        .sanjiao{width:30px;height: 30px;position: absolute;bottom: -20px;left: 50%;transform: translateX(-50%);}
                    }
                }
                .footer-fengxiang_img:hover{
                    .fx{display: block;}
                }
                
            }
        }        
    }
    .footer-code{
        width: 97px;
        text-align: center;
        padding-top: 82px;
        img{width: 100%;margin-bottom: 12px;}
        div{color: #797979;font-size: 14px;}
    }    
}
.footer-con{border-top: 1px solid #666666;padding:15px 0 ;text-align: center;font-size: 15px;color: #666666;cursor: pointer;}
.footer-con:hover{color: #fff;}
.gotop{width: 45px;}
</style>